import { Brick } from '../types/brick.type';
import BrickHelpers from './brick.helpers';
import CustomBrickHelpers from './custom-brick-helper';

class OutputHelper {
    static getOutputFormats = (brick: Brick, brickIds: string[]): Brick => {
        const selectedFormats = brickIds.filter((filteredKey) => filteredKey.startsWith(`${brick?.id}-`)); // Get only the selected formats for the brick.

        // Add formats for only creative bricks.
        if (brick?.subType.startsWith('creative') && selectedFormats.length) {
            const formats = selectedFormats.map((selectedKey) => selectedKey.split('-')[1]);

            // Ensure brick.data and brick.data.settings are defined
            brick.data ??= {};
            brick.data.settings ??= {};
            brick.data.settings.formats = formats; // Set only the selected formats.
        }

        return brick;
    };

    /**
     * Gets selected bricks
     * @returns selected bricks
     */
    static getBricksForOutput = (bricksId: string[]): Brick[] => {
        const selectedBricks: string[] = bricksId;

        // Make sure to include the parent brick if a custom brick is selected
        for (let i = 0; i < bricksId.length; i++) {
            const brick = BrickHelpers.getBrickById(bricksId[i]);
            if (!brick) continue;

            if (CustomBrickHelpers.isCustomBrick(brick) && brick.parentId && !bricksId.includes(brick.parentId)) {
                selectedBricks.push(brick.parentId);
            }
        }

        return selectedBricks
            .map((id) => BrickHelpers.getBrickById(id)) // Get the brick by id.
            .filter((brick): brick is Brick => !!brick && !CustomBrickHelpers.isCustomBrick(brick)) // Filter out custom bricks and groups.
            .map((brick) => this.getOutputFormats(brick, bricksId)); // Add creative formats to the brick if needed.
    };
}

export default OutputHelper;
