import ComponentStore from 'components/data/ComponentStore';
import { MultiModel } from 'components/template-designer/data/template-designer-store';
import BrickHelpers from './brick.helpers';
import { BricksComponentStore, BricksObject } from '../types/bricksComponentStore.type';
import { MODEL_TEMP_BRICK, MODEL_UPDATED_AT, MODEL_UPDATED_BRICKS } from '../constants';
import { Brick } from '../types/brick.type';
import CustomBrickHelpers from './custom-brick-helper';

interface SetBrickModelOptions {
    disableMultiEdit?: boolean;
}

/** Helper class for the bricks component store. */
class BricksComponentStoreHelper {
    /**
     * Delete a brick from the brick component store based on the provided brick id.
     * @param brickId The brick id to be delete.
     * @returns The updated bricks object or undefined if the brick is not found.
     */
    static deleteBrick = (brickId: string): boolean => {
        const bricksComponentStore: BricksComponentStore | undefined = ComponentStore.get('Bricks');
        const bricks = bricksComponentStore?.bricks;

        if (!bricks) return false;

        const brickPrefix = BrickHelpers.getBrickPrefix(brickId);

        ComponentStore.removeItem('Bricks', brickPrefix);

        return true; // Return the deleted brick id.
    };

    /**
     * Delete multiple bricks and their children from the brick component store based on the provided brick ids.
     * @param brickIds The brick ids to delete.
     */
    static deleteMultipleBricks = (brickIds: string[]): boolean => {
        const bricksModels = brickIds.flatMap((brickId) => {
            const brick = BrickHelpers.getBrickById(brickId);

            if (!brick) return [];

            const brickChildren = BrickHelpers.getChildrenOfBrick(brick);
            const allBricks = [brick, ...brickChildren];
            return allBricks.map((brick) => BrickHelpers.getBrickPrefix(brick.id));
        });

        ComponentStore.removeItems('Bricks', bricksModels);
        return true;
    };

    /**
     * Get the checked bricks from the brick component store that are none custom bricks.
     */
    private static getCheckedBricks = (): Brick['id'][] => {
        const checkedBricksObject: undefined | BricksObject = ComponentStore.get('Bricks')?.checkedBricks;

        return checkedBricksObject
            ? Object.keys(checkedBricksObject).filter((brickId) => {
                  const isChecked = checkedBricksObject[brickId];
                  const brick = BrickHelpers.getBrickById(brickId);
                  const isCustom = brick && CustomBrickHelpers.isCustomBrick(brick);
                  return !isCustom && isChecked; // Checked bricks should not be custom bricks and should be checked.
              })
            : [];
    };

    /**
     * Update a model in the brick component store based on the provided brick id and model key.
     * Keep in mind the bricks prefix is already set, so there is no need to add the ("bricks.i_" + "brickId") prefix to your model.
     * @param brickId The id of the brick, the brick id will be added to the prefix, so there is no need to the brick id to your model.
     * @param model The key of the component model. e.g. "roles.assignees". Keep in mind the bricks prefix is already set, so there is no need to add the "bricks.i_" prefix to your model.
     * @param value The value to set for the given model key.
     * @param options Additional options for the setBrickModel function.
     */
    static setBrickModel = (brickId: string, model: string, value: unknown, options?: SetBrickModelOptions): void => {
        // If there is no brickId, that means we are working with a temporary brick in the request flow.
        if (!brickId) {
            ComponentStore.setModel('Bricks', `${MODEL_TEMP_BRICK}.${model}`, value);
            return;
        }

        const models: MultiModel = [];
        const checkedBricks: Brick['id'][] = this.getCheckedBricks();

        const bricksToUpdate: Brick['id'][] = (() => {
            // Don't update the title for multiple bricks
            if (model === 'title') return [brickId];

            if (checkedBricks?.length && !options?.disableMultiEdit) return checkedBricks;

            return [brickId];
        })();

        // This is in case a few bricks are selected but you update a value in another brick
        if (!bricksToUpdate.includes(brickId)) bricksToUpdate.push(brickId);

        bricksToUpdate.forEach((brickId: Brick['id']) => {
            const brickPrefix = BrickHelpers.getBrickPrefix(brickId); // Get brick prefix together with the brick id, e.g. bricks.i_3423423.
            const brickIdPrefix = BrickHelpers.getBrickIdPrefix(brickId); // Get brick id prefix, e.g. i_3423423.
            const updatedBrickModelPath = `${MODEL_UPDATED_BRICKS}.${brickIdPrefix}`; // Path for saving the updated brick id to the component store.
            let modelPath = `${brickPrefix}.${model}`; // Path for saving the value to the component store.

            if (!model) {
                modelPath = brickPrefix;
            }

            models.push([modelPath, value]);
            models.push([updatedBrickModelPath, brickId]);
            models.push([`${brickPrefix}.${MODEL_UPDATED_AT}`, new Date().toISOString()]); // This is a temporary solution for updating the updated at date, ideally this should be done in the backend.
        });

        ComponentStore.setMultiModels('Bricks', models);
    };
}

export default BricksComponentStoreHelper;
