import { TemplateType } from 'types/templates.type';
import Format from 'components/template-designer/types/format.type';
import { MultiLevelCheckboxListOption } from 'components/ui-base/FormFlow/fields/MultiLevelCheckboxList';
import Translation from 'components/data/Translation';
import AssetGalleryDialogSelectorType from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogSelectorType';
import { AssetValidationResult } from '../components/shared/components/creative-input/components/alert-dialog';
import { Preset } from './preset';
import { BrickPublish } from './brick-publish.type';

export interface BrickMetadata {
    status?: string;
    dateStart?: string;
    dateEnd?: string;
    dateDeadline?: string;
}

export interface BrickTree {
    id: string;
    parentId?: string;
    type?: 'creative';
    order?: number;
    children: BrickTree[];
}

export interface Brick {
    id: string;
    campaignId: number;
    accountId: number;
    subType: BrickSubType;
    type: BrickType;
    title: string;
    masterBrickId?: string;
    feedRowId?: string;
    parentId?: string;
    entity?: string;
    metadata?: BrickMetadata;
    parentSubItemId?: string;
    childTitle?: string;
    data?: {
        briefing?: {
            description?: string;
            attachments?: {
                url: string;
                fileName: string;
            }[];
            roles?: {
                reviewers?: number[];
                assignees?: number[];
            };
        };
        dataset?: {
            feedId: string;
            datasetId: string;
        };
        format?: string; // This is used for custom creative format bricks, we need it in order to determine the current format.
        settings?: {
            formats?: string[]; // This is used to override the active formats just before publishing.
            [key: string]: any;
        };
        [key: string]: any;
    };
    publish?: BrickPublish;
    results?: BrickResults;
    subItems?: Brick[];
    pointer?: string;
    parentPointer?: string;
    childrenCount?: number;
    childrenPagination?: BrickChildrenPagination;
    createdAt: Date;
    createdBy: number;
    updatedAt: Date;
    updatedBy: number;
    // TODO: DO WE NEED THESE?
    subSectionType?: SubSectionType;
    selectedPlatforms?: MultiLevelCheckboxListOption[];
    templateType?: TemplateType;
    order?: number;
}

export type BrickToCreate = Omit<Brick, 'id' | 'accountId' | 'createdAt' | 'createdBy' | 'updatedAt' | 'updatedBy'>;

/**
 * This explains the level of the brick in the tree structure. The difference between 'child' and 'subItem' is that
 * a child is a level deeper than its parent (campaign > adset) and a subitem is a multiSocial brick that is on the same level as
 * other subItems, but gets its default data from the master brick (multiSocial master campaign brick > facebook campaign)
 */
// export type BrickType = 'main' | 'child' | 'master' | 'master_child;
export enum BrickType {
    MAIN = 'main',
    CHILD = 'child',
    MASTER = 'master',
    MASTER_CHILD = 'master_child'
}

/**
 * These are the different options in the campaign hierarchy. For example, a campaign can have diffent adsets, an
 * adset can have different ads. The hierarchical structure depends on the platform. An adset can be called adGroup
 * in another platform, but it will still be the 'adset' level
 */
export type CampaignLevel = 'campaign' | 'adset' | 'ad' | 'placement';

/**
 * These are the different options that the subsection rows can have in the tree view. A subsection row is not a brick,
 * but a seperator to group different bricks
 */
export type SubSectionType = 'platforms' | 'adsets' | 'ads' | 'master' | 'output';

export type BrickPublishStatus = 'readyToPublish' | 'incomplete' | 'published' | 'failed' | 'publishing';

export interface BrickPublishResults {
    creatives: object;
    id: string;
    url: string;
}

export interface BrickResults {
    asset: BrickItemResults;
}

export interface BrickItemResults {
    errors?: string[];
    warnings?: string[];
}

export interface BrickPublishResults extends BrickItemResults {
    resources: {
        [key: string]: string;
    };
}

export interface BrickData {
    asset?: any; // TODO Remove?
    assetValidationResults?: {
        errors?: AssetValidationResult[];
        warnings?: AssetValidationResult[];
    };
    presets?: Preset[];
    uploadRestrictionType?: 'free' | 'restricted';
    overwrittenProperties?: string[];
    identifier?: string;
    template?: any;
    templateType?: TemplateType;
    formats?: Format[];
    groupKey?: string;
    adSetupType?: AdSetupType;
    selectedPlatforms?: BrickSubType[];
    assetUploadType?: 'free' | 'restricted';
}

export interface BrickBriefingAttachment {
    url: string;
    fileName: string;
}

export interface BrickBriefingData {
    description?: string;
    attachments?: BrickBriefingAttachment[];
}

export interface BrickAssetSet extends Brick {
    data?: {
        uploadRestrictionType?: 'free' | 'restricted';
        presets?: Preset[];
        assets?: any[];
    };
}

export interface BrickSingleAsset extends Brick {
    data?: {
        uploadRestrictionType?: 'free' | 'restricted';
        presets?: Preset[];
    };
}

export interface BrickMetaAd extends Brick {
    data: {
        publish?: {
            data?: {
                name?: string;
                customize_name?: boolean;
                adAccountId?: string;
                status?: string;
                audience_id?: string;
                bid_amount?: number;
                conversion_domain?: string;
                date_format?: string;
                display_sequence?: number;
                draft_id?: number;
                engagement_audience_id?: string;
                include_demolink_hashes?: 'OFF' | 'ON';
                priority?: number;
                tracking_specs?: string;
            };
        };
    };
}

export interface BrickMetaAdSet extends Brick {
    data: {
        publish?: {
            data?: {
                name?: string;
                customize_name?: boolean;
                adAccountId?: string;
                promoted_object?: {
                    page_id: unknown;
                };
                optimization_goal?: string;
                bid_amount?: number;
                bid_strategy?: string;
                billing_event?: string;
                frequency_control_specs?: {
                    event?: string;
                    interval_days?: number;
                    max_frequency?: number;
                };
                start_time?: string;
                end_time?: string;
                budget_mode?: 'DAILY' | 'LIFETIME';
                budget?: number;
                targeting?: {
                    geo_locations?: {
                        locations?: any;
                    };
                    gender?: 'all' | 'male' | 'female';
                    age?: any;
                    demographics?: any;
                    behaviors?: any;
                    locales?: any;
                };
                dsa_beneficiary?: string;
                dsa_payor?: string;
                is_dynamic_creative?: boolean;
            };
        };
    };
}

export interface BrickMetaCampaign extends Brick {
    data: {
        publish?: {
            data?: {
                name?: string;
                customize_name?: boolean;
                adAccountId?: string;
                special_ad_categories?: string[];
                special_ad_category_country?: string;
                buying_type?: string;
                objective?: string;
                spend_cap?: number;
                status?: string;
                budget_optimization_on?: boolean;
                budget_mode?: 'DAILY' | 'LIFETIME';
                budget?: number;
                bid_strategy?: string;
                pacing_type?: string;
                is_skadnetwork_attribution?: boolean;
                promoted_object?: {
                    application_id?: number;
                };
            };
        };
    };
}

export interface BrickSetup {
    subType: BrickSubType;
    title: string;
    description: string;
    addBrickText: string;
    defaultTab?: string;
    /**
     * If you want to open a certain tab based on the data of the brick, you can use this function to determine the tab
     */
    dynamicTab?: (data: any) => any;
    channel?: BrickChannel;
    platform?: BrickSocialPlatform;
    availableParentBricks: BrickSubType[];
    availableChildBricks: BrickSubType[];
    /**
     * This is used to add additional data to the brick multi input. We need this for validation/conditional rendering.
     * For example, the meta adset needs to know certain data of the meta campaign
     */
    additionalVars?: BrickSubType[];
    outputAction?: BrickOutputAction;
    tabs: BrickSetupTab[];
    isCustomBrick?: boolean;
    config?: {
        adSetup?: {
            hasAdPlacements?: boolean;
            allowsMultiFrame?: boolean;
        };
        general?: {
            creativeSelector?: {
                multiple?: boolean;
                canEditCreative?: boolean;
                isButton?: boolean;
                selectors?: AssetGalleryDialogSelectorType[];
                templateTypes?: TemplateType[];
                fileType?: FileType | FileType[];
            };
            publish?: {
                previewType?: 'settings' | 'creatives' | 'social';
            };
        };
    };
}

export type BrickOutputAction = 'publish' | 'download' | 'all';

export type BrickRights = 'brickManagement' | 'brickRequest' | 'brickPublish' | 'brickReview';

/**
 * The tab that is available in the brick setup
 */
export interface BrickSetupTab {
    key: string;
    title: string;
    type?: string;
    /**
     * The path to the component
     */
    component?: string;
    /**
     * The user needs one of these rights to see the tab
     */
    rights?: BrickRights[];
}

export interface BrickListOption {
    title: string;
    description?: string;
    channel?: string;
    type?: string;
    identifier?: string;
    image?: string;
    icon?: string;
    subType: BrickSubType;
}

/**
 * The available brick sub types
 */
export type BrickSubType =
    | 'all'
    | 'group'
    | 'email'
    | 'landingpage'
    | 'meta_campaign'
    | 'meta_adset'
    | 'meta_ad'
    | 'tiktok_campaign'
    | 'tiktok_adgroup'
    | 'tiktok_ad'
    | 'snapchat_campaign'
    | 'snapchat_adsquad'
    | 'snapchat_ad'
    | 'pinterest_campaign'
    | 'pinterest_adgroup'
    | 'linkedin_campaignGroup'
    | 'linkedin_campaign'
    | 'linkedin_ad'
    | 'multiSocial_campaign'
    | 'multiSocial_adset'
    | 'multiSocial_ad'
    | 'asset_set'
    | 'single_asset'
    | 'youtube_ad'
    | 'youtube_post'
    | 'feed_custom'
    | 'cm360_campaign'
    | 'cm360_placementGroup'
    | 'cm360_placement'
    | 'cm360_ad'
    | 'creative_all'
    | 'creative_image'
    | 'creative_video'
    | 'creative_displayad'
    | 'creative_pdf'
    | 'creative_format';

type BrickNameMapping = { [key in BrickSubType]: string };

/**
 * The mapping of brick types and their display names
 */
export const brickNameMapping: BrickNameMapping = {
    all: 'All',
    group: 'Group',
    email: 'Email',
    landingpage: 'Landingpage',
    meta_campaign: 'Meta Campaign',
    meta_adset: 'Meta Adset',
    meta_ad: 'Meta Ad',
    tiktok_campaign: 'TikTok Campaign',
    tiktok_adgroup: 'TikTok Adgroup',
    tiktok_ad: 'TikTok Ad',
    snapchat_campaign: 'Snapchat Campaign',
    snapchat_adsquad: 'Snapchat Adsquad',
    snapchat_ad: 'Snapchat Ad',
    pinterest_campaign: 'Pinterest Campaign',
    pinterest_adgroup: 'Pinterest Adgroup',
    linkedin_campaignGroup: 'LinkedIn Campaign Group',
    linkedin_campaign: 'LinkedIn Campaign',
    linkedin_ad: 'LinkedIn Ad',
    multiSocial_campaign: 'MultiSocial Campaign',
    multiSocial_adset: 'MultiSocial Adset',
    multiSocial_ad: 'MultiSocial Ad',
    asset_set: Translation.get('mediaSet', 'bricks'),
    single_asset: Translation.get('singleMediaFile', 'bricks'),
    youtube_ad: 'YouTube Ad',
    youtube_post: 'YouTube Post',
    feed_custom: 'Feed Custom',
    cm360_campaign: 'CM360 Campaign',
    cm360_placementGroup: 'CM360 Placement Group',
    cm360_placement: 'CM360 Placement',
    cm360_ad: 'CM360 Ad',
    creative_all: 'Creative All',
    creative_image: 'Creative Image',
    creative_video: 'Creative Video',
    creative_displayad: 'Creative Display Ad',
    creative_pdf: 'Creative PDF',
    creative_format: 'Creative Format'
};

/**
 * The category of the block
 */
export type BrickChannel = 'all' | 'assets' | 'web' | 'feed' | 'display' | 'social' | 'video' | 'email' | 'group' | 'dooh';

/**
 * The setup of the creative for an ad. This can be a prebuild template or a custom upload
 */
export type AdSetupType = 'template' | 'custom';
export type AdTemplateType = 'displayAdDesigned' | 'dynamicImageDesigned';
export type BrickDataInfo =
    | 'widget'
    | 'setup'
    | 'settings'
    | 'settingsInputs'
    | 'steps'
    | 'publish'
    | 'preview'
    | 'metadata'
    | 'icon'
    | 'validators'
    | 'briefing'
    | 'briefingInputs'
    | 'defaultData'
    | 'adSetup'
    | 'adSetupSingleFrame'
    | 'adSetupMultiFrame'
    | 'placements'
    | 'channels';
export type BrickSocialPlatform =
    | 'multi'
    | 'meta'
    | 'instagram'
    | 'linkedin'
    | 'snapchat'
    | 'youtube'
    | 'twitter'
    | 'wechat'
    | 'tiktok'
    | 'pinterest'
    | 'cm360';

export interface UpdatedBricks {
    [id: string]: { location: string; value: string };
}

export type FileType = 'image' | 'jpg' | 'png' | 'imagegif' | 'video' | 'audio' | 'pdf' | 'zip' | 'document' | 'txt' | 'font' | 'all';

export type ImageFileExt = 'jpg' | 'jpeg' | 'png' | 'gif' | 'webp' | 'tif' | 'tiff' | 'svg';
export type PDFFileExt = 'pdf';
export type VideoFileExt = 'mp4' | 'avi' | 'mov' | 'wmv' | 'flv' | 'mpeg' | 'mpegps' | '3gp' | 'webm' | 'hevc' | 'm2v' | 'm4v';
export type AudioFileExt = 'mp3' | 'wav' | 'ogg' | 'aac' | 'flac' | 'm4a';
export type ZipFileExt = 'zip';
export type FileExt = ImageFileExt | PDFFileExt | VideoFileExt | AudioFileExt | ZipFileExt;

export interface BrickFeedData {
    datasetId: string;
    feedId: string;
}

// Feed brick has pagination
export interface BrickChildrenPagination {
    // Current page
    offset: number;
    // Items in the page
    limit: number;
}

export interface BrickGeneral {
    showWarning?: (brick: Brick) => string;
}

export interface BrickRoles {
    reviewers: number[];
    assignees: number[];
}
