import allBricks from 'components/bricks/components/bricks';
import allChannels from 'components/bricks/data/allChannels';
import getCustomerConfig from 'components/bricks/data/customerConfig';
import { Brick, BrickChannel, BrickOutputAction, BrickSetup, BrickSubType } from 'components/bricks/types/brick.type';
import { Channel } from 'components/bricks/data/allChannels';
import ComponentStore from 'components/data/ComponentStore';
import { BrickViewState } from './brick-view-state.helpers';
import BrickHelpers from './brick.helpers';
import { BricksObject } from '../types/bricksComponentStore.type';
import ValidateHelpers from './validate.helpers';

// Get all the bricks that are available for everyone, filtered by the config of the customer and, optionally,
// the channel and the search term
const getAllAvailableBricks = (channel?: BrickChannel, searchTerm?: string, availableBricks?: BrickSubType[]): BrickSetup[] => {
    let allAvailableBricks = Object.values(allBricks)
        .filter((brick) => {
            if (!brick.setup) return false;

            // If availableBricks is specified, filter by its inclusion
            if (availableBricks) {
                return availableBricks.includes('all') || availableBricks.includes(brick.setup.subType);
            }

            return true; // No availableBricks specified, include all bricks
        })
        .map((brick) => brick.setup);

    // Don't include the custom bricks
    allAvailableBricks = allAvailableBricks.filter((brick) => !brick.isCustomBrick);

    // Filter by the config of the customer
    allAvailableBricks = filterBricksByConfig(allAvailableBricks);

    // Filter by the channel
    if (channel) {
        allAvailableBricks = filterItemsByChannel(allAvailableBricks, channel);
    }

    // Filter by the searchterm
    if (searchTerm) {
        allAvailableBricks = filterItemsBySearchTerm(allAvailableBricks, searchTerm, ['title', 'description']);
    }

    return allAvailableBricks;
};

const filterBricksByConfig = (bricks: BrickSetup[]) => {
    const config = getCustomerConfig();
    const configAvailableItems = config?.availableBricks;

    if (!configAvailableItems || configAvailableItems.length === 0) return bricks;

    // Filter out all the bricks that do not have matching values with the config (except for 'group', always show that one)
    return bricks.filter((brick) => {
        return configAvailableItems.some((configItem) => {
            return Object.keys(configItem).every((key) => brick[key] === configItem[key] || brick[key] === 'group');
        });
    });
};

/**
 * This function takes a list of items and returns a filtered list of items that match the search term. The search term is matched
 * against the values of the keys that are passed in the filterByKeys parameter. If an item has a 'searchIndex' property, the search
 * term is also matched against that property.
 * @example filterItemsBySearchTerm(filteredPresets, searchTerm, ['title', 'description, fileType']) -> Filters on the values of these three keys
 * @param items A list of items to filter
 * @param searchTerm The search term to match against
 * @param filterByKeys The keys to match the search term against
 * @returns A filtered list of items
 */
const filterItemsBySearchTerm = <T>(items: T[], searchTerm: string, filterByKeys: string[]): T[] => {
    if (!searchTerm) return items;

    const lowerCaseSearchTerm = searchTerm.toLowerCase().trim();

    return items.filter((item) => {
        return filterByKeys.some((key) => {
            const value = item[key]?.toLowerCase();
            const searchIndex = item['searchIndex']?.toLowerCase();
            return (value && value.includes(lowerCaseSearchTerm)) || (searchIndex && searchIndex.includes(lowerCaseSearchTerm));
        });
    });
};

const filterItemsByChannel = <T extends { channel?: BrickChannel }>(items: T[], channel: BrickChannel): T[] => {
    if (!channel || channel === 'all') return items;
    return items.filter((item) => item.channel === channel);
};

/**
 * This function takes a list of items and returns a filtered list of channels that are available for those items.
 * @param items The items to filter the channels for (for example, a list of bricks or a list of format presets)
 * @param hideChannels An array of channels that should be hidden (for example, 'all' and 'group')
 */
const getFilteredAvailableChannels = <T extends { channel?: BrickChannel }>(items: T[], hideChannels?: BrickChannel[], channels?: Channel[]): Channel[] => {
    const channelsArray = channels && channels.length ? channels : allChannels;

    const filteredAvailableChannels = channelsArray.filter((channel) => {
        // Filter out the channels that are not in the available bricks
        const availableFilteredChannels = items.filter((item) => item.channel).map((item) => item.channel);

        // Return true if either the channel is in the available bricks or the channel is 'all'
        return availableFilteredChannels.includes(channel.type) || channel.type === 'all';
    });

    // Hide certain channels
    if (hideChannels) {
        return filteredAvailableChannels.filter((channel) => !hideChannels.includes(channel.type));
    }

    return filteredAvailableChannels;
};

/**
 * Save the filters in the BrickViewState and the ComponentStore.
 */
const saveBricksFilters = (filters: object): void => {
    BrickViewState.set('filters', filters);
    ComponentStore.setModel('Bricks', 'filters', filters);
};

/**
 * Filter bricks by the provided output action.
 */
const filterBricksByOutputAction = (brickIds: string[], action: BrickOutputAction): string[] => {
    if (!brickIds?.length) return [];

    return brickIds.filter((brickId) => {
        const brick = BrickHelpers.getBrickById(brickId);
        const brickSetup = BrickHelpers.getBrickData<BrickSetup | undefined>(brick?.subType, 'setup');

        return brickSetup?.outputAction === action || (action === 'download' && brickSetup?.outputAction === 'all');
    });
};

/**
 * Filter out the bricks that are not in the provided list of ids.
 * Keep in mind the children of the bricks are also included in the result.
 */
const filterBricksObjectByIds = (bricks: BricksObject, parentIdsToInclude: string[]): BricksObject => {
    const result: BricksObject = {};

    for (const brickId of parentIdsToInclude) {
        const children = BrickHelpers.getBrickAndInnerChildren(bricks, brickId);

        for (const childId of children) {
            const brick = BrickHelpers.getBrickById(childId);

            if (brick && !result[brick.id]) {
                const id = BrickHelpers.getBrickIdPrefix(brick.id);

                result[id] = brick; // Add the brick to the result
            }
        }
    }

    return result;
};

/**
 * Filter the bricks by the provided output action for the provided bricks object.
 */
const filterBricksObjectByOutputAction = (bricks: BricksObject, action: BrickOutputAction): BricksObject => {
    const bricksArray = Object.values(bricks);
    const filteredBricks: BricksObject = {};

    for (const brick of bricksArray) {
        const setup = BrickHelpers.getBrickData<BrickSetup | undefined>(brick.subType, 'setup');
        const brickIdPrefix = BrickHelpers.getBrickIdPrefix(brick.id);

        if (setup?.outputAction === action || setup?.outputAction === 'all') filteredBricks[brickIdPrefix] = brick;
    }

    return filteredBricks;
};

/** Filter out bricks that contain validation errors */
const filterOutBricksWithValidationErrors = (bricks: Brick[]): Brick[] => {
    return bricks.filter((brick) => !ValidateHelpers.isBrickWithValidationErrors(brick.id));
};

export {
    getAllAvailableBricks,
    getFilteredAvailableChannels,
    filterItemsByChannel,
    filterItemsBySearchTerm,
    saveBricksFilters,
    filterBricksByOutputAction,
    filterBricksObjectByOutputAction,
    filterBricksObjectByIds,
    filterOutBricksWithValidationErrors
};
