import AssetManagementService from 'components/asset-management/services/asset-management.service';
import { CreativeV2Enriched } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import { CreativeV2Helpers } from 'components/creatives-v2/helpers/creatives-v2.helpers';
import { Brick, BrickSetup } from '../types/brick.type';
import BrickHelpers from './brick.helpers';
import { BricksObject } from '../types/bricksComponentStore.type';

/**
 * Helper class to handle custom bricks
 */
class CustomBrickHelpers {
    static filterActiveFormats = (creative: CreativeV2Enriched, formatsToDelete?: string[]): string[] => {
        const activeFormats: string[] = creative.data.settings.activeFormats;
        return activeFormats.filter((format) => !formatsToDelete?.includes(format));
    };

    /**
     * Deactivate a custom bricks.
     */
    static handleCustomBrickDelete = async (brickId: string, formatsToDelete?: string[]): Promise<void> => {
        const brick = BrickHelpers.getBrickById(brickId);

        if (!brick) return;

        const creative = brick.data?.creatives[0];
        const enrichCreative: CreativeV2Enriched = await CreativeV2Helpers.enrichCreative(creative);

        enrichCreative.data.settings.activeFormats = this.filterActiveFormats(enrichCreative, formatsToDelete); // Remove the current format from the active formats.

        await AssetManagementService.patchAsset({ _id: creative.assetManagerId, data: enrichCreative.data, type: 'creativeV2' } as any); // Make sure the format is removed from the asset.
    };

    /**
     * Deactivate multiple custom bricks.
     */
    static handleMultiCustomBricksDelete = async (brickIds: string[]): Promise<void> => {
        const bricks = brickIds.map((brickId) => BrickHelpers.getBrickById(brickId));
        const bricksToDelete: string[] = [];

        // We only need to delete the formats of the parent brick.
        bricks.forEach((brick) => {
            const parentId = brick?.parentId;
            if (parentId && !bricksToDelete.includes(parentId)) {
                bricksToDelete.push(parentId);
            }
        });

        // Filter out the formats that needs to be deleted.
        for (const brickId of bricksToDelete) {
            const formatsToDelete = bricks.filter((brick) => brick?.parentId === brickId && !!brick?.data?.format).map((brick) => brick?.data?.format ?? '');
            await this.handleCustomBrickDelete(brickId, formatsToDelete);
        }
    };

    /**
     * Filter out custom bricks from the bricks object.
     */
    static filterOutCustomBricks = (bricksObject: BricksObject): BricksObject => {
        const filteredBricksObject: BricksObject = {};

        Object.keys(bricksObject).forEach((key) => {
            const brick = bricksObject[key];

            if (!this.isCustomBrick(brick)) {
                filteredBricksObject[key] = brick;
            }
        });

        return filteredBricksObject;
    };

    static isCustomBrick = (brick: Brick): boolean => {
        const brickSetup: BrickSetup | undefined = BrickHelpers.getBrickData(brick.subType, 'setup');
        return brickSetup?.isCustomBrick || false;
    };
}

export default CustomBrickHelpers;
